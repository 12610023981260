.container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  flex-direction: row;
}

.calculator-container {
  margin-top: 1.4em;
  margin-left: 1em;
  background-color: #fff;
  flex: 1;
  margin-right: 20px;
}

.best-value-container {
  flex: 1;
}

.investment-property-calculator {
  background-color: #f5f5f5;
  padding: 20px;
}

.label {
  margin-right:.5em;
  font-weight: bold;
}

.input {
  margin-bottom: 10px;
}

.button {
  margin-top: 10px;
}

.result {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}

.draggable-card {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}

.best-value-properties {
  padding: 20px;
  height: 800px;
  overflow-y: auto;
}

.property-card {
  height: 200px;
}

.drop-area {
  border: 2px dashed #aaa;
  padding: 20px;
  text-align: center;
}

.dropped-card {
  margin-bottom: 1em;
  background-color: #f6f6f6;
  padding: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  box-sizing: border-box;
  padding: 1em;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin: 10px;
  }

  .calculator-container,
  .best-value-container {
    margin-top: 0.7em;
    margin-left: 0.5em;
    margin-right: 10px;
    flex-basis: 100%;
  }

  .login-container {
    padding: 0.5em;
  }

  .label,
  .input,
  .button,
  .result {
    font-size: smaller;
  }
}

.notes {
  width: 15em;
  height: 5em;
  display: flex;
}